import { Model } from '@vuex-orm/core'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import { durationToHuman } from '@/helpers/i18n_helpers'
import Sensor from '@/models/Sensor'

export default class Hub extends Model {
  static entity = 'devices__hubs'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      serialNumber: this.string(''),
      activeSiteName: this.string(''),
      voltage: this.number(null),
      gsmSignal: this.number(null),
      activeHwSensorsCount: this.number(0),
      activeHwSensors: this.hasMany(Sensor, 'hubId'),

      fwUploadInterval: this.attr(null),
      fwDownloadInterval: this.attr(null),
      fwDownloadDuration: this.attr(null),
      fwScaleInterval: this.attr(null),
      fwWeightTriggerThreshold: this.attr(null),
      fwTemperatureTriggerThreshold: this.attr(null),
      fwHumidityTriggerThreshold: this.attr(null)
    }
  }

  get formattedVoltage () {
    return ChartFormatters.voltage(this.voltage)
  }

  get formattedFwUploadInterval () {
    if (!this.fwUploadInterval) { return null }
    return durationToHuman(this.fwUploadInterval, 'minutes')
  }

  get formattedFwDownloadInterval () {
    if (!this.fwDownloadInterval) { return null }
    return durationToHuman(this.fwDownloadInterval, 'minutes')
  }

  get formattedFwScaleInterval () {
    if (!this.fwScaleInterval) { return null }
    return durationToHuman(this.fwScaleInterval, 'minutes')
  }
}
