<template>
  <fragment v-if="!loading">
    <site-list-item
      v-for="(site, sidx) in sites"
      :key="'site-' + sidx"
      :name="site.name"
      :hives="site.hives"
    />
  </fragment>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Site from '@/models/Site'
import SiteListItem from './HivesNavigation/SiteListItem'

export default {
  components: { SiteListItem },
  computed: {
    ...mapState('hives_navigation', ['loading']),
    sites () {
      return Site.query().with('hives').all()
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    ...mapActions('hives_navigation', ['load'])
  }
}
</script>
