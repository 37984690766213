<template>
  <v-list-item @click="selectOne">
    <v-list-item-action>
      <v-icon>fa-archive</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title :title="name">
        {{ name | truncate(15) }}
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action v-show="false">
      <v-switch
        :input-value="isSelected(id)"
        value
        @click.native.stop
        @change="toggle"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters('hives_navigation', ['selectedHives', 'isSelected'])
  },

  methods: {
    ...mapActions('hives_navigation', ['toggleHive', 'selectOneHive']),
    toggle (value) {
      this.toggleHive({ id: this.id, value })
    },

    selectOne (event) {
      this.selectOneHive({ id: this.id })
    }
  }
}
</script>
