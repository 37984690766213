const DEFAULT_TOOLBAR_TITLE = 'Forsage - App'

export const state = () => ({
  toolbarTitle: DEFAULT_TOOLBAR_TITLE
})

export const mutations = {
  setToolbarTitle (state, title) {
    state.toolbarTitle = title || DEFAULT_TOOLBAR_TITLE
  }
}
