import { Model } from '@vuex-orm/core'
import Hive from './Hive'
import HiveSite from './HiveSite'

export default class Site extends Model {
  static entity = 'hives_navigation__sites'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      latitude: this.attr(null),
      longitude: this.attr(null),
      hives: this.belongsToMany(Hive, HiveSite, 'site_id', 'hive_id')
    }
  }
}
