<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height>
        <nuxt />
      </v-container>
    </v-content>
    <span class="d-none">Commit: {{ commitHash }}</span>
  </v-app>
</template>

<script>
export default {
  computed: {
    commitHash () {
      return process.env.COMMIT_HASH
    }
  }
}
</script>
