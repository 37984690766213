import to from 'await-to-js'
import Vue from 'vue'
import Session from '@/models/Session'
import AccountMembership from '@/models/AccountMembership'
import currentUserQuery from '~/gql/currentUser'

function parseJwtToken (token) {
  return JSON.parse(atob(token.split('.')[1]))
}

export default async function (ctx) {
  const { store, app, redirect } = ctx
  const jwtToken = app.$apolloHelpers.getToken()
  if (!jwtToken) {
    return redirect('/login')
  }
  const tokenPayload = parseJwtToken(jwtToken)

  const client = app.apolloProvider.defaultClient
  const [err, res] = await to(client.query({ query: currentUserQuery }))
  if (err) {
    return redirect('/login')
  }

  const {
    data: { currentUser, currentAccount }
  } = res
  if (!currentUser) {
    return redirect('/login')
  } else if (app.$ga && !tokenPayload.impersonated) {
    app.$ga.set({ userId: currentUser.id })
  }
  Vue.prototype.$currentUser = currentUser

  Session.insertOrUpdate({
    data: {
      id: 'singleton',
      currentUserId: currentUser.id,
      currentUser,
      currentAccountId: currentAccount.id,
      currentAccount
    }
  })

  currentUser.accountMemberships.forEach(({ id, role, account, user }) => {
    AccountMembership.insertOrUpdate({
      data: {
        id,
        role,
        account,
        user,
        userId: user.id,
        accountId: account.id
      }
    })
  })

  store.commit('layout/setToolbarTitle')

  return true
}
