<template>
  <fragment>
    <v-list-group v-model="opened" prepend-icon="fa-chevron-down" sub-group color="black">
      <template #activator>
        <v-list-item-title>{{ name }}</v-list-item-title>
      </template>

      <hive-list-item
        v-for="(hive, hidx) in hives"
        :id="hive.id"
        :key="'hive-' + hidx"
        :name="hive.name"
      />
    </v-list-group>
  </fragment>
</template>

<script>
import HiveListItem from './HiveListItem'

export default {
  components: {
    HiveListItem
  },

  props: {
    name: { type: String, required: true },
    hives: { type: Array, default: () => [] }
  },

  data () {
    return {
      opened: false
    }
  }
}
</script>
