import { getClient } from '@/lib/apollo_helpers'
import sensorsOverviewQuery from '@/gql/hives/sensors_overview'
import calculateTimeWindow from '@/helpers/calculate_time_window'
import * as SensorsOverview from '@/models/SensorsOverview'
import Sensor from '@/models/Sensor'
import { last, includes, reject, intersection, flatMap, countBy, forOwn, keys, min, max, groupBy, compact, sortBy } from 'lodash'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import { availableColors } from '@/helpers/chart_colors'
import { $t } from '@/helpers/i18n_helpers'

const yAxisDefinition = ({ seriesName, title, color, min, max, formatter, metric, opposite = false }) => ({
  metric,
  seriesName,
  opposite,
  tooltip: { enabled: true },
  // title: { text: title, style: { color } },
  axisTicks: { show: true },
  axisBorder: { show: true, color },
  labels: {
    formatter,
    style: { colors: color }
  },
  min,
  max
})

export const state = () => ({
  loading: true,
  hiveId: null,
  pastToolbar: ['1d', '1w', '4w', '12w', '24w', '52w'],
  metricPast: '1w',
  metricSince: null,
  metricTill: null,
  toggleMetricCounter: 0
})

export const getters = {
  hive (state) {
    return SensorsOverview.Hive.query().with('sensorPlacements.sensor').find(state.hiveId)
  },

  sensorPlacements (state) {
    return SensorsOverview.SensorPlacement.query().with('sensor').where('hiveId', state.hiveId).orderBy('weight', 'desc').get()
  },

  sensorPlacementMetricDataFrames (_state, getters) {
    let dataFrames = flatMap(SensorsOverview.SensorPlacement.query().with('sensor').all().map(sensorPlacement => (
      SensorsOverview.SensorPlacementMetricDataFrame
        .query()
        .with('sensorPlacement')
        .where('sensorPlacementId', sensorPlacement.id)
        .where('metric', metric => includes(sensorPlacement.showMetrics, metric))
        .get()
    )))

    return sortBy(dataFrames, 'metric')
  },

  chartData (_state, getters) {
    return getters.sensorPlacementMetricDataFrames.map(dataFrame => ({
      data: dataFrame.data,
      name: dataFrame.labelWithSensor,
      type: 'line'
    }))
  },

  chartYAxis (_state, getters) {
    let grouped = groupBy(getters.sensorPlacementMetricDataFrames, 'metric')

    let mins = {}; let maxs = {}
    forOwn(grouped, (group, metricName) => {
      mins[metricName] = min(compact(group.map(({ min }) => min)))
      maxs[metricName] = max(compact(group.map(({ max }) => max)))
    })

    const showWeight = includes(keys(grouped), 'weight')
    const showTemperature = includes(keys(grouped), 'temperature')
    const showHumidity = includes(keys(grouped), 'humidity')

    const axis = compact([
      showWeight && yAxisDefinition({
        metric: 'weight',
        seriesName: $t('hive_overview.metrics.weight'),
        title: $t('hive_overview.metrics.weight'),
        min: mins.weight && mins.weight - 1000,
        max: maxs.weight && maxs.weight + 1000,
        // color: metricColors.weight,
        formatter: ChartFormatters.weight
      }),

      showTemperature && yAxisDefinition({
        metric: 'temperature',
        seriesName: $t('hive_overview.metrics.temperature'),
        title: $t('hive_overview.metrics.temperature'),
        min: mins.temperature && mins.temperature - 1,
        max: maxs.temperature && maxs.temperature + 1,
        // color: metricColors.temperature,
        formatter: ChartFormatters.temperature,
        opposite: true
      }),

      showHumidity && yAxisDefinition({
        metric: 'humidity',
        seriesName: $t('hive_overview.metrics.humidity'),
        title: $t('hive_overview.metrics.humidity'),
        min: 0,
        max: 100,
        // color: metricColors.humidity,
        formatter: ChartFormatters.humidity,
        opposite: true
      })
    ])

    return sortBy(axis, 'metric')
  },

  chartColors (_state) {
    return availableColors
  }

}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  incrementToggleMetricCounter (state) {
    state.toggleMetricCounter = state.toggleMetricCounter + 1
  },

  setHiveId (state, hiveId) {
    state.hiveId = hiveId
  },

  setRange (state, { past, since, till }) {
    state.metricPast = past
    state.metricSince = since
    state.metricTill = till
  }
}

export const actions = {
  async load ({ commit, state, getters, dispatch }, payload) {
    commit('setLoading', true)

    const { initial } = (payload || {})
    if (initial) {
      SensorsOverview.Hive.deleteAll()
      SensorsOverview.SensorPlacement.deleteAll()
      SensorsOverview.SensorPlacementMetricDataFrame.deleteAll()
    }

    let variables = {
      id: state.hiveId,
      metricPast: state.metricPast,
      metricSince: state.metricSince,
      metricTill: state.metricTill
    }

    if (variables.metricPast === 'custom') {
      variables.metricPast = null
    } else {
      variables.metricSince = null
      variables.metricTill = null
    }

    variables.metricTime = calculateTimeWindow({
      past: variables.metricPast,
      since: variables.metricSince,
      till: variables.metricTill
    })

    let { data } = await getClient(this).query({
      query: sensorsOverviewQuery,
      variables
    })

    const { hive } = data
    const { activeHwSensorPlacements } = hive

    SensorsOverview.Hive.insertOrUpdate({
      data: {
        id: hive.id,
        name: hive.name,
        internalName: hive.internalName,
        hwSensorPlacementsCount: activeHwSensorPlacements.length,

        sensorPlacements: activeHwSensorPlacements.map((sensorPlacement) => {
          const hwSensor = sensorPlacement.hwSensor || {}
          const { voltage, humidity, temperature, weight } = last(sensorPlacement.recentHwSensorMetrics) || {}

          return {
            id: sensorPlacement.id,
            hiveId: Number(hive.id),
            name: hwSensor.internalName,
            sensor: {
              id: hwSensor.id,
              name: hwSensor.internalName,
              serialNumber: hwSensor.serialNumber,
              activeMetrics: hwSensor.activeMetrics
            },

            metricDataFrames: (hwSensor.activeMetrics || []).map((metric) => {
              let min, max
              let dataFrameRecords = sensorPlacement.hwSensorMetrics.map((hwSensorMetric) => {
                const value = hwSensorMetric[metric]
                if (value) {
                  if (!min || value < min) { min = value }
                  if (!max || value > max) { max = value }
                }

                return {
                  x: hwSensorMetric.time,
                  y: value
                }
              })

              return {
                metric,
                sensorPlacementId: parseInt(sensorPlacement.id),
                data: dataFrameRecords,
                min,
                max
              }
            }),
            voltage,
            humidity,
            temperature,
            weight
          }
        })
      }
    })

    if (initial) {
      let activatedMetrics = []

      getters.sensorPlacements.forEach(({ id, sensor }) => {
        if (intersection(sensor.activeMetrics, activatedMetrics).length === 0) {
          activatedMetrics = activatedMetrics.concat(sensor.activeMetrics)
          dispatch('toggleMetric', { sensorPlacementId: id, metric: 'all' })
        }
      })
    }

    commit('setLoading', false)
  },

  setRange ({ commit, dispatch }, { past, since, till }) {
    if (past) {
      commit('setRange', { past })
    } else {
      commit('setRange', { past: 'custom', since, till })
    }
    dispatch('load', { initial: false })
  },

  toggleMetric ({ commit }, { sensorPlacementId, metric }) {
    const sensorPlacement = SensorsOverview.SensorPlacement.query().with('sensor').find(sensorPlacementId)
    if (!sensorPlacement) { return null }
    if (metric === 'all' && sensorPlacement.showingAllMetrics) { return null }

    if (metric === 'all') {
      SensorsOverview.SensorPlacement.update({
        where: sp => intersection(sensorPlacement.sensor.activeMetrics, sp.showMetrics).length > 0,
        data: { showMetrics: [] }
      })

      SensorsOverview.SensorPlacement.update({
        where: sensorPlacementId,
        data (sensorPlacement) {
          const sensor = Sensor.find(sensorPlacement.sensorId)
          sensorPlacement.showMetrics = sensor.activeMetrics
        }
      })
    } else {
      SensorsOverview.SensorPlacement.update({
        where: sensorPlacementId,
        data (sensorPlacement) {
          if (includes(sensorPlacement.showMetrics, metric)) {
            if (sensorPlacement.showMetrics.lenght === 1) { return null }
            sensorPlacement.showMetrics = reject(sensorPlacement.showMetrics, m => m === metric)
          } else {
            sensorPlacement.showMetrics = [...sensorPlacement.showMetrics, metric]
          }
        }
      })
    }

    let shownMetricsCounts = countBy(
      flatMap(
        SensorsOverview.SensorPlacement.all(),
        sp => sp.showMetrics
      ),
      m => m
    )

    if (shownMetricsCounts[metric] > 1) {
      SensorsOverview.SensorPlacement.update({
        where: sensorPlacement => reject(sensorPlacement.showMetrics, m => m === metric).length > 0,
        data: (sensorPlacement) => { sensorPlacement.showMetrics = reject(sensorPlacement.showMetrics, m => m !== metric) }
      })
    }

    forOwn(shownMetricsCounts, (count, countedMetric) => {
      if (countedMetric === metric) { return }
      if (count <= 1) { return }

      SensorsOverview.SensorPlacement.update({
        where: sensorPlacement => includes(sensorPlacement.showMetrics, countedMetric),
        data: (sensorPlacement) => { sensorPlacement.showMetrics = reject(sensorPlacement.showMetrics, m => m === countedMetric) }
      })
    })

    commit('incrementToggleMetricCounter')
  }
}
