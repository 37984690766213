<template>
  <fragment>
    <slot :open="open" />
    <v-dialog v-show="false" v-model="dialog" max-width="500px">
      <v-card v-if="dialog">
        <v-card-title>
          <span class="headline">{{ $t('navigation.account_selector.title') }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert v-for="error in errorMessages" :key="error" :value="error" type="error">
            {{ error }}
          </v-alert>

          <v-list>
            <v-list-item v-for="account in accounts" :key="account.id" @click="selectAccount(account)">
              <v-list-item-action>
                <v-icon>fa-briefcase</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ account.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import Session from '@/models/Session'
import selectUserAccountMutation from '@/gql/users/selectAccount'
import { flatMap } from 'lodash'
import to from 'await-to-js'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      errors: []
    }
  },

  computed: {
    accounts () {
      let session = Session.query().with('currentUser.accountMemberships.account').first()
      return session.currentUser.accountMemberships.map(({ account }) => account)
    },

    errorMessages () {
      return flatMap(this.errors, ({ name, errorCodes }) => {
        return errorCodes.map((errorCode) => {
          return `${name} ${errorCode}`
        })
      })
    }
  },

  methods: {
    ...mapActions('hives_navigation', { loadNavigation: 'load' }),
    open () {
      this.dialog = true
    },

    async selectAccount (account) {
      const [err, res] = await to(
        this.$apollo.mutate({
          mutation: selectUserAccountMutation,
          variables: { accountId: account.id }
        })
      )

      if (err) {
        this.errors = [{ name: '', errorCode: err }]
      }

      let {
        data: {
          selectUserAccount: { jwt, result: { success, errors } }
        }
      } = res
      if (!success) {
        this.errors = errors?.details || []
      } else {
        this.dialog = false
        await this.$apolloHelpers.onLogin(jwt)
        if (window.nsWebViewInterface) {
          window.nsWebViewInterface.emit('login', { jwt })
        }
        await this.loadNavigation()
        this.$router.push({ path: '/account_refresh' })
      }
    }
  }
}
</script>

<style>

</style>
