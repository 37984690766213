<template>
  <div class="d-flex justify-center pa-5">
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
export default {

}
</script>
