import { Model } from '@vuex-orm/core'
import AccountMembership from '@/models/AccountMembership'

export default class User extends Model {
  static entity = 'users'

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      email: this.string(''),
      admin: this.boolean(false),
      demo: this.boolean(false),
      accountMemberships: this.hasMany(AccountMembership, 'userId')
    }
  }
}
