<template>
  <div v-if="show" class="ma-0 pa-0">
    <div v-if="expanded" class="d-flex flex-row ma-0 pa-0">
      <TopBarWeatherDay
        v-for="daily in forecast"
        :key="daily.dt"
        :date="daily.dt * 1000"
        :temperature="daily.temp.day"
        :icon="daily.weather[0].icon"
      />
      <div class="d-flex justify-center px-3 cursor-pointer">
        <V-btn icon @click="toggle">
          <v-icon>fa-chevron-right</v-icon>
        </V-btn>
      </div>
    </div>
    <div v-else>
      <div text class="px-0 cursor-pointer" @click="toggle">
        <TopBarWeatherDay
          :date="todaysForecast.dt * 1000"
          :temperature="todaysForecast.temp.day"
          :icon="todaysForecast.weather[0].icon"
          :border-right="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import TopBarWeatherDay from '@/components/TopBarWeatherDay'
export default {
  components: { TopBarWeatherDay },
  computed: {
    ...mapState('site_weather', ['site', 'show', 'forecast', 'expanded']),
    todaysForecast () {
      return this.forecast[0]
    }
  },
  methods: {
    ...mapActions('site_weather', ['load']),
    ...mapMutations('site_weather', ['toggle'])
  }
}
</script>

<style></style>
