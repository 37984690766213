import { min, max, compact } from 'lodash'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import { availableColors, metricColors } from '@/helpers/chart_colors'
import { $t } from '@/helpers/i18n_helpers'

const dataToChartFormat = (data) => {
  return data.map(dataPoint => ({
    ...dataPoint,
    x: dataPoint.time,
    y: parseFloat(dataPoint.value)
  }))
}

const has2DistinctValues = (dataPoints) => {
  if (dataPoints.length <= 1) { return false }
  let firstValue
  let firstValueIndex = 0
  for (let i = firstValueIndex; i < dataPoints.length; i++) {
    firstValueIndex = i
    if (dataPoints[i].value) {
      firstValue = dataPoints[i].value
      break
    }
  }

  for (let i = firstValueIndex + 1; i < dataPoints.length; i++) {
    if (dataPoints[i].value && firstValue !== dataPoints[i].value) { return true }
  }
  return false
}

export default class HiveChartSeriesProvider {
  constructor (hive) {
    this.hive = hive
  }

  chartData (hiveDataAttribute) {
    if (!this._chartData) {
      this._chartData = {
        weight: dataToChartFormat(this.hive.weightDataPoints),
        temperature: dataToChartFormat(this.hive.temperatureDataPoints),
        humidity: dataToChartFormat(this.hive.humidityDataPoints)
      }
    }

    return this._chartData[hiveDataAttribute]
  }

  isMetricActive (metric) {
    if (!this._isMetricActive) { this._isMetricActive = {} }
    if (!this._isMetricActive.hasOwnProperty[metric]) {
      if (metric === 'weight') { this._isMetricActive[metric] = has2DistinctValues(this.hive.weightDataPoints) }
      if (metric === 'dht_temp') { this._isMetricActive[metric] = has2DistinctValues(this.hive.temperatureDataPoints) }
      if (metric === 'dht_hum') { this._isMetricActive[metric] = has2DistinctValues(this.hive.humidityDataPoints) }
    }
    return this._isMetricActive[metric]
    // return this.hive.activeMetrics.includes(metric)
  }

  series () {
    return compact([
      this.isMetricActive('weight') && { name: $t('hive_overview.metrics.weight'), type: 'line', data: this.chartData('weight') },
      this.isMetricActive('dht_temp') && { name: $t('hive_overview.metrics.temperature'), type: 'line', data: this.chartData('temperature') },
      this.isMetricActive('dht_hum') && { name: $t('hive_overview.metrics.humidity'), type: 'line', data: this.chartData('humidity') }
    ])
  }

  colors () {
    let colors = compact([
      this.isMetricActive('weight') && metricColors.weight,
      this.isMetricActive('dht_temp') && metricColors.temperature,
      this.isMetricActive('dht_hum') && metricColors.humidity
    ])

    if (colors.length === 0) { return availableColors }

    return colors
  }

  yAxis () {
    return compact([
      this.isMetricActive('weight') && {
        seriesName: $t('hive_overview.metrics.weight'),
        axisTicks: { show: true },
        axisBorder: { show: true, color: metricColors.weight },
        labels: {
          style: { colors: metricColors.weight },
          formatter: ChartFormatters.weight
        },
        min: this.chartMin('weight') - 1,
        max: this.chartMax('weight') + 1,
        title: { text: $t('hive_overview.metrics.weight'), style: { color: metricColors.weight } },
        tooltip: { enabled: true }
      },
      this.isMetricActive('dht_temp') && {
        seriesName: $t('hive_overview.metrics.temperature'),
        opposite: true,
        axisTicks: { show: true },
        axisBorder: { show: true, color: metricColors.temperature },
        labels: {
          style: { colors: metricColors.temperature },
          formatter: ChartFormatters.temperature
        },
        min: this.chartMin('temperature') - 1,
        max: this.chartMax('temperature') + 1,
        title: { text: $t('hive_overview.metrics.temperature'), style: { color: metricColors.temperature } },
        tooltip: { enabled: true }
      },
      this.isMetricActive('dht_hum') && {
        seriesName: $t('hive_overview.metrics.humidity'),
        opposite: true,
        axisTicks: { show: true },
        axisBorder: { show: true, color: metricColors.humidity },
        labels: {
          style: { colors: metricColors.humidity },
          formatter: ChartFormatters.humidity
        },
        min: 0,
        max: 100,
        title: { text: $t('hive_overview.metrics.humidity'), style: { color: metricColors.humidity } },
        tooltip: { enabled: true }
      }
    ])
  }

  chartMin (hiveDataAttribute) {
    let data = this.chartData(hiveDataAttribute)
    let minValue = min(compact(data.map(record => record.y)))
    if (!minValue) { return null }
    return parseFloat(minValue.toFixed(0))
  }

  chartMax (hiveDataAttribute) {
    let data = this.chartData(hiveDataAttribute)
    let maxValue = max(compact(data.map(record => record.y)))
    if (!maxValue) { return null }
    return parseFloat(maxValue.toFixed(0))
  }
}
