const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['no_auth'] = require('../middleware/no_auth.js')
middleware['no_auth'] = middleware['no_auth'].default || middleware['no_auth']

export default middleware
