import { getClient } from '@/lib/apollo_helpers'
import hubQuery from '@/gql/devices/hub'
import * as Devices from '@/models/Devices'
import { last } from 'lodash'

export const state = () => ({
  loading: true
})

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  }
}

export const actions = {
  async load (context, hubId) {
    context.commit('setLoading', true)

    let { data } = await getClient(this).query({
      query: hubQuery,
      variables: { id: hubId }
    })

    const { currentAccount: { hwHubs: { nodes: hwHubs } } } = data
    const hwHub = hwHubs[0]
    const { recentMetrics } = hwHub
    const { voltage, gsmSignal } = last(recentMetrics) || {}

    Devices.Hub.insert({
      data: {
        id: hwHub.id,
        name: hwHub.internalName,
        serialNumber: hwHub.serialNumber,
        activeSiteName: hwHub.activeSite?.name,
        activeHwSensorsCount: hwHub.activeHwSensors.totalCount,
        fwUploadInterval: hwHub.fwUploadInterval,
        fwDownloadInterval: hwHub.fwDownloadInterval,
        fwDownloadDuration: hwHub.fwDownloadDuration,
        fwScaleInterval: hwHub.fwScaleInterval,
        fwWeightTriggerThreshold: hwHub.fwWeightTriggerThreshold,
        fwTemperatureTriggerThreshold: hwHub.fwTemperatureTriggerThreshold,
        fwHumidityTriggerThreshold: hwHub.fwHumidityTriggerThreshold,
        voltage,
        gsmSignal,
        activeHwSensors: hwHub.activeHwSensors.nodes.map((sensorNode) => {
          const { id, internalName, serialNumber, recentMetrics, activeHive, activeMetrics } = sensorNode
          const { voltage, dhtHum, dhtTemp, weight, time: lastMetricTimestamp } = last(recentMetrics) || {}

          return {
            id,
            name: internalName,
            serialNumber,
            voltage,
            humidity: dhtHum,
            temperature: dhtTemp,
            weight,
            lastMetricTimestamp,
            activeHiveName: activeHive && activeHive.name,
            activeMetrics
          }
        })

      }
    })

    context.commit('setLoading', false)
  }
}
