<template>
  <div
    :class="[
      'ma-0',
      'pl-2',
      'd-none',
      'd-sm-flex',
      'flex-row',
      'align-items-center',
      borderRight && 'border-right',
    ]"
  >
    <div class="ma-0 pa-0 pa-0 d-flex flex-column justify-center">
      <div class="ma-0 pa-0 date">
        {{ $moment(date).format('D.M.') }}
      </div>

      <div class="ma-0 pa-0 temperature">
        {{ parseFloat(temperature).toFixed(0) }} ℃
      </div>
    </div>

    <div class="ma-0 pa-0 d-flex flex-column justify-center">
      <v-img :src="iconUrl" height="50" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: Number,
      required: true
    },
    temperature: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    borderRight: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    iconUrl () {
      return `https://openweathermap.org/img/wn/${this.icon}@2x.png`
    }
  }
}
</script>

<style scoped>
.border-right {
  border-right: 2px #333 solid;
}
.temperature {
  font-size: 0.75rem;
  white-space: nowrap;
}

.date {
  font-size: 0.95rem;
  font-weight: bold;
}
</style>
