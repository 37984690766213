<template>
  <v-app>
    <v-app-bar
      :clipped-left="clipped"
      app
      color="primary"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title v-text="toolbarTitle" />
      <v-spacer />
      <TopBarWeather />
      <v-btn
        icon
        @click="logout"
      >
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      app
    >
      <v-list>
        <account-selector>
          <template #default="slotProps">
            <v-list-item @click="slotProps.open">
              <v-list-item-action>
                <v-icon>fa-briefcase</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ currentAccount.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </account-selector>

        <v-divider />

        <hives-navigation />

        <v-divider />

        <v-list-item
          v-for="(item, i) in allowedItems"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="nativeApp" link @click="navigateToSensorsScan">
          <v-list-item-action>
            <v-icon>bluetooth</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('navigation.drawer.scan_sensors') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template #append>
        <v-list-item v-show="false" @click="miniVariant = !miniVariant">
          <v-list-item-action>
            <v-icon v-if="miniVariant">
              fa-chevron-right
            </v-icon>
            <v-icon v-else>
              fa-chevron-left
            </v-icon>
          </v-list-item-action>
          <v-list-item-title>
            Sbalit
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-content>
      <v-container fluid class="px-0">
        <nuxt />
      </v-container>
    </v-content>
    <v-footer
      :fixed="
        fixed"
      app
      class="d-none d-sm-flex"
    >
      <span>&copy; 2020</span>
      <span class="d-none">Commit: {{ commitHash }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import HivesNavigation from '@/components/HivesNavigation'
import TopBarWeather from '@/components/TopBarWeather'
import AccountSelector from '@/components/AccountSelector'
import Session from '@/models/Session'
import { mapState } from 'vuex'

export default {
  components: { HivesNavigation, TopBarWeather, AccountSelector },
  data () {
    return {
      clipped: true,
      drawer: true,
      fixed: false,
      items: [
        {
          icon: 'fa-archive',
          title: this.$t('navigation.drawer.hives'),
          to: '/sites/dashboard',
          admin: false
        },
        {
          icon: 'bug_report',
          title: 'Debug',
          to: '/hives/debug',
          admin: true
        },
        {
          icon: 'fa-chart-line',
          title: 'Statistics',
          to: '/hives/statistics',
          admin: true
        },
        {
          icon: 'bar_chart',
          title: 'Sound',
          to: '/hives/sound_statistics',
          admin: true
        },
        {
          icon: 'surround_sound',
          title: 'Librosa',
          to: '/hives/librosa_statistics',
          admin: true
        },
        {
          icon: 'developer_board',
          title: this.$t('navigation.drawer.devices'),
          to: '/devices/hubs',
          admin: false
        },
        {
          icon: 'fa-user-circle',
          title: this.$t('navigation.drawer.profile'),
          to: '/profile',
          admin: false
        }
      ],
      miniVariant: false,
      right: true
    }
  },

  computed: {
    allowedItems () {
      return this.items.filter(i => this.allowed(i))
    },

    commitHash () {
      return process.env.COMMIT_HASH
    },

    currentAccount () {
      return Session.query().with('currentAccount').first().currentAccount
    },

    nativeApp () {
      return navigator.userAgent === 'ForsageApp'
    },

    ...mapState('layout', ['toolbarTitle'])
  },

  methods: {
    logout () {
      this.$apolloHelpers.onLogout()
      if (window.nsWebViewInterface) {
        window.nsWebViewInterface.emit('logout')
      }
      this.$router.push({ path: '/login' })
    },

    allowed (item) {
      if (!item.admin) { return true }
      return this.$currentUser.admin
      // return true
    },

    navigateToSensorsScan () {
      window.nsWebViewInterface.emit('menu_scan_sensors')
    }
  }
}
</script>
