export function weight (value, _index, showSign = false) {
  if (!value) { return '-' }
  let valueKg = (value / 1000).toFixed(2)
  let plusSign = showSign && valueKg > 0 ? '+' : ''
  return `${plusSign}${valueKg} Kg`
}

export function normalizedWeight (value, index) {
  return weight(value, index, true)
}

export function humidity (value) {
  if (!value) { return '-' }
  return value.toFixed(2) + ' %'
}

export function temperature (value) {
  if (!value) { return '-' }
  return value.toFixed(2) + ' ℃'
}

export function voltage (value) {
  if (!value) { return '-' }
  return Number(value).toFixed(3) + 'v'
}
