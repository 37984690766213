import { getClient } from '@/lib/apollo_helpers'
import sensorQuery from '@/gql/devices/sensorWithMetrics'
import Sensor from '@/models/Sensor'
import { last, compact, max, min } from 'lodash'
import calculateTimeWindow from '@/helpers/calculate_time_window'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import { metricColors, availableColors } from '@/helpers/chart_colors'
import { $t } from '@/helpers/i18n_helpers'

const dataSeries = (metrics, field) => (
  metrics.map(metric => ({
    x: metric.time,
    y: parseFloat(metric[field])
  }))
)

const has2DistinctValues = (dataPoints, field) => {
  if (!dataPoints) { return false }
  if (dataPoints.length <= 1) { return false }
  let firstValue
  let firstValueIndex = 0
  for (let i = firstValueIndex; i < dataPoints.length; i++) {
    firstValueIndex = i
    if (dataPoints[i][field]) {
      firstValue = dataPoints[i][field]
      break
    }
  }

  for (let i = firstValueIndex + 1; i < dataPoints.length; i++) {
    if (dataPoints[i][field] && firstValue !== dataPoints[i][field]) { return true }
  }
  return false
}

const chartMax = (dataPoints, field, offset = 0) => {
  const maxValue = max(compact(dataPoints.map(p => p[field])))
  if (!maxValue) { return null }
  return parseFloat(maxValue.toFixed(2)) + offset
}

const chartMin = (dataPoints, field, offset = 0) => {
  const minValue = min(compact(dataPoints.map(p => p[field])))
  if (!minValue) { return null }
  return parseFloat(minValue.toFixed(2)) - offset
}

const yAxisDefinition = ({ seriesName, title, color, min, max, formatter, opposite = false }) => ({
  seriesName,
  opposite,
  tooltip: { enabled: true },
  // title: { text: title, style: { color } },
  axisTicks: { show: true },
  axisBorder: { show: true, color },
  labels: {
    formatter,
    style: { colors: color }
  },
  min,
  max
})

export const state = () => ({
  loading: true,
  sensorId: null,
  pastToolbar: ['1d', '1w', '4w', '12w', '24w', '52w'],
  metricPast: '1w',
  metricSince: null,
  metricTill: null,

  showMetrics: {
    voltage: true,
    weight: true,
    dhtTemp: true,
    dhtHum: true
  }
})

export const getters = {
  sensor (state) {
    return Sensor.query().find(state.sensorId)
  },

  hasMetric (state, getters) {
    return (metric) => {
      return (
        state.showMetrics[metric] &&
        has2DistinctValues(getters.sensor.nrfMetrics, metric)
      )
    }
  },

  chartSeries (_state, getters) {
    return compact([
      getters.hasMetric('weight') && {
        name: $t('hive_overview.metrics.weight'),
        type: 'line',
        data: dataSeries(getters.sensor.nrfMetrics, 'weight')
      },

      getters.hasMetric('dhtTemp') && {
        name: $t('hive_overview.metrics.temperature'),
        type: 'line',
        data: dataSeries(getters.sensor.nrfMetrics, 'dhtTemp')
      },

      getters.hasMetric('dhtHum') && {
        name: $t('hive_overview.metrics.humidity'),
        type: 'line',
        data: dataSeries(getters.sensor.nrfMetrics, 'dhtHum')
      },

      getters.hasMetric('voltage') && {
        name: $t('hive_overview.metrics.voltage'),
        type: 'line',
        data: dataSeries(getters.sensor.nrfMetrics, 'voltage')
      }
    ])
  },

  chartYAxis (_state, getters) {
    return compact([
      getters.hasMetric('weight') && yAxisDefinition({
        seriesName: $t('hive_overview.metrics.weight'),
        title: $t('hive_overview.metrics.weight'),
        min: chartMin(getters.sensor.nrfMetrics, 'weight', 1),
        max: chartMax(getters.sensor.nrfMetrics, 'weight', 1),
        color: metricColors.weight,
        formatter: ChartFormatters.weight
      }),

      getters.hasMetric('dhtTemp') && yAxisDefinition({
        seriesName: $t('hive_overview.metrics.temperature'),
        title: $t('hive_overview.metrics.temperature'),
        min: chartMin(getters.sensor.nrfMetrics, 'dhtTemp', 1),
        max: chartMax(getters.sensor.nrfMetrics, 'dhtTemp', 1),
        color: metricColors.temperature,
        formatter: ChartFormatters.temperature,
        opposite: true
      }),

      getters.hasMetric('dhtHum') && yAxisDefinition({
        seriesName: $t('hive_overview.metrics.humidity'),
        title: $t('hive_overview.metrics.humidity'),
        min: 0,
        max: 100,
        color: metricColors.humidity,
        formatter: ChartFormatters.humidity,
        opposite: true
      }),

      getters.hasMetric('voltage') && yAxisDefinition({
        seriesName: $t('hive_overview.metrics.voltage'),
        title: $t('hive_overview.metrics.voltage'),
        min: chartMin(getters.sensor.nrfMetrics, 'voltage', 0.01),
        max: chartMax(getters.sensor.nrfMetrics, 'voltage', 0.01),
        color: metricColors.voltage,
        formatter: ChartFormatters.voltage,
        opposite: true
      })
    ])
  },

  chartColors (_state, getters) {
    let colors = compact([
      getters.hasMetric('weight') && metricColors.weight,
      getters.hasMetric('dhtTemp') && metricColors.temperature,
      getters.hasMetric('dhtHum') && metricColors.humidity,
      getters.hasMetric('voltage') && metricColors.voltage
    ])

    if (colors.length === 0) { return availableColors }

    return colors
  }
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  setSensorId (state, sensorId) {
    state.sensorId = sensorId
  },

  setRange (state, { past, since, till }) {
    state.metricPast = past
    state.metricSince = since
    state.metricTill = till
  },

  toggleMetric (state, metric) {
    state.showMetrics[metric] = !state.showMetrics[metric]
  }
}

export const actions = {
  async load ({ commit, state }) {
    commit('setLoading', true)

    let variables = {
      id: state.sensorId,
      metricPast: state.metricPast,
      metricSince: state.metricSince,
      metricTill: state.metricTill
    }

    if (variables.metricPast === 'custom') {
      variables.metricPast = null
    } else {
      variables.metricSince = null
      variables.metricTill = null
    }

    variables.metricTime = calculateTimeWindow({
      past: variables.metricPast,
      since: variables.metricSince,
      till: variables.metricTill
    })

    let { data } = await getClient(this).query({
      query: sensorQuery,
      variables
    })

    const { currentAccount: { hwSensors: { nodes: hwSensors } } } = data
    const hwSensor = hwSensors[0]
    const { recentMetrics, nrfMetrics, activeMetrics } = hwSensor
    const { voltage, dhtHum, dhtTemp, weight, time: lastMetricTimestamp } = last(recentMetrics) || {}
    const activeHive = hwSensor.activeHive || {}

    Sensor.insert({
      data: {
        id: hwSensor.id,
        name: hwSensor.internalName,
        serialNumber: hwSensor.serialNumber,
        activeHiveName: activeHive.name,
        voltage,
        temperature: dhtTemp,
        humidity: dhtHum,
        lastMetricTimestamp,
        weight,
        nrfMetrics,
        activeMetrics
      }
    })

    commit('setLoading', false)
  },

  setRange ({ commit, dispatch }, { past, since, till }) {
    if (past) {
      commit('setRange', { past })
    } else {
      commit('setRange', { past: 'custom', since, till })
    }
    dispatch('load')
  }
}
