import { Model } from '@vuex-orm/core'
import User from '@/models/User'

export default class Account extends Model {
  static entity = 'accounts'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      users: this.hasMany(User, 'userId')
    }
  }
}
