import { Model } from '@vuex-orm/core'
import User from '@/models/User'
import Account from '@/models/Account'

export default class AccountMembership extends Model {
  static entity = 'account_memberships'

  static fields () {
    return {
      id: this.number(null),
      role: this.string(''),
      userId: this.string(null),
      user: this.belongsTo(User, 'userId'),
      accountId: this.string(null),
      account: this.belongsTo(Account, 'accountId')
    }
  }
}
