import { Model } from '@vuex-orm/core'
import Site from './Site'
import HiveSite from './HiveSite'
import HiveMetricStats from './HiveMetricStats'
export default class Hive extends Model {
  static entity = 'hives'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      sites: this.belongsToMany(Site, HiveSite, 'hive_id', 'site_id'),
      internalName: this.string(''),
      hwSensorPlacementsCount: this.number(null),
      metricStats: this.hasOne(HiveMetricStats, 'hiveId')
    }
  }
}
