import { Model } from '@vuex-orm/core'
import { difference } from 'lodash'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import Sensor from '@/models/Sensor'
import Hive from './Hive'
import SensorPlacementMetricDataFrame from './SensorPlacementMetricDataFrame'

export default class SensorPlacement extends Model {
  static entity = 'sensors_overview__sensor_placements'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      since: this.attr(null),
      till: this.attr(null),
      serialNumber: this.string(''),
      hiveId: this.attr(null),
      hive: this.belongsTo(Hive, 'hiveId'),
      sensorId: this.attr(null),
      sensor: this.belongsTo(Sensor, 'sensorId'),

      voltage: this.number(null),
      humidity: this.number(null),
      temperature: this.number(null),
      weight: this.number(null),

      showMetrics: this.attr([]),
      metricDataFrames: this.hasMany(SensorPlacementMetricDataFrame, 'sensorPlacementId')
    }
  }

  get showingAllMetrics () {
    return difference(this.sensor.activeMetrics, this.showMetrics).length === 0
  }

  get availableMetrics () {
    return [
      this.humidity
    ]
  }

  get formattedVoltage () {
    if (!this.voltage) { return null }
    return ChartFormatters.voltage(this.voltage)
  }

  get formattedHumidity () {
    if (!this.humidity) { return null }
    return ChartFormatters.humidity(this.humidity)
  }

  get formattedTemperature () {
    if (!this.temperature) { return null }
    return ChartFormatters.temperature(this.temperature)
  }

  get formattedWeight () {
    if (!this.weight) { return null }
    return ChartFormatters.weight(this.weight)
  }
}
