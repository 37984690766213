import { Model } from '@vuex-orm/core'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import moment from 'moment'
import Hive from '@/models/Hive'

export default class Sensor extends Model {
  static entity = 'sensors'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      serialNumber: this.string(''),
      hubId: this.number(null),
      voltage: this.number(null),
      humidity: this.number(null),
      temperature: this.number(null),
      weight: this.number(null),
      activeHiveName: this.attr(null),
      lastMetricTimestamp: this.attr(null),
      activeHiveId: this.attr(null),
      activeHive: this.belongsTo(Hive, 'activeHiveId'),

      nrfMetrics: this.attr([]),
      activeMetrics: this.attr([])
    }
  }

  get formattedVoltage () {
    if (!this.voltage) { return null }
    return ChartFormatters.voltage(this.voltage)
  }

  get formattedHumidity () {
    if (!this.humidity) { return null }
    return ChartFormatters.humidity(this.humidity)
  }

  get formattedTemperature () {
    if (!this.temperature) { return null }
    return ChartFormatters.temperature(this.temperature)
  }

  get formattedWeight () {
    if (!this.weight) { return null }
    return ChartFormatters.weight(this.weight)
  }

  get formattedLastMetricTimestamp () {
    if (!this.lastMetricTimestamp) { return null }
    return moment(this.lastMetricTimestamp).format('ddd DD.MM.YYYY HH:mm:ss')
  }

  metricActive (metric) {
    return this.activeMetrics.includes(metric)
  }
}
