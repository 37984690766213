import { getClient } from '@/lib/apollo_helpers'
import hubsQuery from '@/gql/devices/hubs'
import * as Devices from '@/models/Devices'
import { last } from 'lodash'

export const state = () => ({
  loading: true
})

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  }
}

export const actions = {
  async load (context) {
    context.commit('setLoading', true)
    let { data } = await getClient(this).query({
      query: hubsQuery
    })

    Devices.Hub.deleteAll()

    const { currentAccount: { hwHubs: { nodes } } } = data

    const insertData = nodes.map((node) => {
      const { id, internalName, serialNumber, activeHwSensors: { totalCount }, recentMetrics } = node
      const { voltage } = last(recentMetrics) || {}
      return {
        id, name: internalName, serialNumber, activeHwSensorsCount: totalCount, voltage
      }
    })

    Devices.Hub.insert({
      data: insertData
    })

    context.commit('setLoading', false)
  }
}
