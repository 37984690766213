import { Model } from '@vuex-orm/core'
import User from '@/models/User'
import Account from '@/models/Account'

export default class Session extends Model {
  static entity = 'sessions'

  static fields () {
    return {
      id: this.attr('singleton'),
      currentUserId: this.attr(null),
      currentUser: this.belongsTo(User, 'currentUserId'),
      currentAccountId: this.attr(null),
      currentAccount: this.belongsTo(Account, 'currentAccountId')
    }
  }
}
