import Vue from 'vue'
import Session from '@/models/Session'

export default function (ctx) {
  const { app } = ctx
  const jwtToken = app.$apolloHelpers.getToken()
  if (!jwtToken) {
    return true
  }

  app.$apolloHelpers.onLogout()
  Vue.prototype.$currentUser = null
  Session.deleteAll()

  return true
}
