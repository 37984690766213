import { getClient } from '@/lib/apollo_helpers'
import changeCurrentUserPasswordMutation from '@/gql/users/changeCurrentUserPassword'
import { $t } from '@/helpers/i18n_helpers'

export const state = () => ({
  loading: false,
  errors: [],
  fields: {
    oldPassword: null,
    newPassword: null,
    newPasswordConfirmation: null
  }
})

export const getters = {
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  setField (state, data) {
    state.fields = { ...state.fields, ...data }
  },

  addErrors (state, newErrors) {
    state.errors = [...state.errors, ...newErrors]
  },

  clearErrors (state) {
    state.errors = []
  },

  clearFields (state) {
    state.fields = {}
  }
}

export const actions = {
  load ({ commit }) {
    commit('clearErrors')
    commit('clearFields')
  },

  async submit ({ commit, state }) {
    commit('setLoading', true)
    commit('clearErrors')

    const { fields: { oldPassword, newPassword, newPasswordConfirmation } } = state

    if (!newPassword) {
      commit('addErrors', [
        $t('pages.profile.change_password.errors.new_password_blank')
      ])
    }

    if (!oldPassword) {
      commit('addErrors', [
        $t('pages.profile.change_password.errors.old_password_blank')
      ])
    }

    if (newPassword !== newPasswordConfirmation) {
      commit('addErrors', [
        $t('pages.profile.change_password.errors.new_password_confirmation_invalid')
      ])
    }

    if (state.errors.length > 0) {
      commit('setLoading', false)
      return
    }

    // await new Promise((resolve, reject) => {
    //   setTimeout(resolve, 2000)
    // })

    const { data } = await getClient(this).mutate({
      mutation: changeCurrentUserPasswordMutation,
      variables: {
        newPassword,
        oldPassword
      }
    })

    const { changeCurrentUserPassword: { result } } = data
    const { success, errors } = result
    if (success) {
      this.$router.push(`/profile`)
    } else if (errors) {
      const { errorCodes } = errors.details[0]
      errorCodes.forEach(errorCode => {
        commit('addErrors', [
          $t(`pages.profile.change_password.errors.${errorCode}`)
        ])
      })
    }

    commit('setLoading', false)
  }
}
