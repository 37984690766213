var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'ma-0',
    'pl-2',
    'd-none',
    'd-sm-flex',
    'flex-row',
    'align-items-center',
    _vm.borderRight && 'border-right' ]},[_c('div',{staticClass:"ma-0 pa-0 pa-0 d-flex flex-column justify-center"},[_c('div',{staticClass:"ma-0 pa-0 date"},[_vm._v("\n      "+_vm._s(_vm.$moment(_vm.date).format('D.M.'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"ma-0 pa-0 temperature"},[_vm._v("\n      "+_vm._s(parseFloat(_vm.temperature).toFixed(0))+" ℃\n    ")])]),_vm._v(" "),_c('div',{staticClass:"ma-0 pa-0 d-flex flex-column justify-center"},[_c('v-img',{attrs:{"src":_vm.iconUrl,"height":"50"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }