import { Model } from '@vuex-orm/core'
import * as ChartFormatters from '@/helpers/chart_y_formatters'

function format (value, formatter) {
  if (!value) { return null }
  return formatter(value)
}

export default class HiveMetricStats extends Model {
  static entity = 'hive_metric_stats'

  static primaryKey = 'hiveId'

  static fields () {
    return {
      hiveId: this.number(null),
      minHumidity: this.number(null),
      avgHumidity: this.number(null),
      maxHumidity: this.number(null),
      minTemperature: this.number(null),
      avgTemperature: this.number(null),
      maxTemperature: this.number(null),
      currentWeight: this.number(null),
      weightDelta1d: this.number(null)
    }
  }

  get formattedMinHumidity () { return format(this.minHumidity, ChartFormatters.humidity) }
  get formattedAvgHumidity () { return format(this.avgHumidity, ChartFormatters.humidity) }
  get formattedMaxHumidity () { return format(this.maxHumidity, ChartFormatters.humidity) }

  get formattedMinTemperature () { return format(this.minTemperature, ChartFormatters.temperature) }
  get formattedAvgTemperature () { return format(this.avgTemperature, ChartFormatters.temperature) }
  get formattedMaxTemperature () { return format(this.maxTemperature, ChartFormatters.temperature) }

  get formattedCurrentWeight () { return format(this.currentWeight, ChartFormatters.weight) }

  get formattedWeightDelta1d () {
    return format(this.weightDelta1d, v => ChartFormatters.weight(v, null, true))
  }
}
