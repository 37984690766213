import { getClient } from '@/lib/apollo_helpers'
import Sensor from '@/models/Sensor'
import Hive from '@/models/Hive'
import sensorQuery from '@/gql/devices/sensor'
import availableHivesQuery from '@/gql/devices/availableHives'
import assignHwSensorToHiveMutation from '@/gql/devices/assignHwSensorToHive'
import { $t } from '@/helpers/i18n_helpers'

export const state = () => ({
  loading: false,
  errors: [],
  sensorId: null,
  newHiveId: null
})

export const getters = {
  sensor (state) {
    return Sensor.query().with('activeHive').find(state.sensorId)
  },

  availableHives () {
    let hiveModel = Hive
    return Hive.query().all().map(hive => ({
      text: hive.name,
      value: hive.id
    }))
  }
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  setSensorId (state, sensorId) {
    state.sensorId = sensorId
  },

  setNewHiveId (state, newHiveId) {
    state.newHiveId = newHiveId
  },

  addErrors (state, newErrors) {
    state.errors = [...state.errors, ...newErrors]
  },

  clearErrors (state) {
    state.errors = []
  }
}

export const actions = {
  load ({ commit, dispatch }) {
    commit('clearErrors')
    dispatch('loadSensor')
    dispatch('loadAvailableHives')
  },

  async loadSensor ({ state }) {
    let { data } = await getClient(this).query({
      query: sensorQuery,
      variables: { id: state.sensorId }
    })

    const sensorResult = data.currentAccount.hwSensors.nodes[0]

    Sensor.insertOrUpdate({
      data: {
        id: sensorResult.id,
        name: sensorResult.internalName,
        serialNumber: sensorResult.serialNumber,
        activeMetrics: sensorResult.activeMetrics,
        activeHiveId: sensorResult.activeHive?.id,
        activeHive: sensorResult.activeHive
      }
    })
  },

  async loadAvailableHives ({ state }) {
    let { data } = await getClient(this).query({ query: availableHivesQuery })

    const hives = data.currentAccount.hives.nodes

    Hive.insertOrUpdate({
      data: hives.map(({ id, name, internalName }) => ({
        id, name, internalName
      }))
    })
  },

  async submit ({ commit, state }) {
    commit('setLoading', true)
    commit('clearErrors')
    if (!state.newHiveId) {
      commit('addErrors', [
        $t('pages.devices.sensor.change_hive.errors.new_hive_blank')
      ])

      commit('setLoading', false)
      return
    }

    const { data } = await getClient(this).mutate({
      mutation: assignHwSensorToHiveMutation,
      variables: {
        hwSensorId: state.sensorId,
        hiveId: state.newHiveId
      }
    })

    const { assignHwSensorToHive: { result } } = data
    const { success, errors } = result
    if (success) {
      this.$router.push(`/devices/sensors/${state.sensorId}`)
    } else if (errors) {
      commit('addErrors', errors.fullMessages)
    }

    commit('setLoading', false)
  }
}
