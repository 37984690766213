import { getClient } from '@/lib/apollo_helpers'
import { $t } from '@/helpers/i18n_helpers'
import Site from '@/models/Site'
import dashboardQuery from '@/gql/sites/dashboard'
import HiveMetricStats from '@/models/HiveMetricStats'
import SiteMetricStats from '@/models/SiteMetricStats'

export const state = () => ({
  loading: true
})

export const getters = {
  sites () {
    return Site.query().with('metricStats').with('hives.metricStats').all()
  }
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  }
}

export const actions = {
  async load ({ commit, state, getter, dispatch }) {
    commit('setLoading', true)

    let { data } = await getClient(this).query({ query: dashboardQuery })

    const { currentAccount: { sites } } = data

    sites.forEach((site) => {
      const { hives } = site

      SiteMetricStats.insertOrUpdate({
        data: {
          siteId: site.id,
          ...site.metricStats
        }
      })

      hives.forEach((hive) => {
        HiveMetricStats.insertOrUpdate({
          data: {
            hiveId: hive.id,
            ...hive.metricStats
          }
        })
      })
    })
    commit('setLoading', false)
  }
}
