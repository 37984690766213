import { getClient } from '@/lib/apollo_helpers'
import hivesOverviewQuery from '@/gql/hives/hivesOverview'
import * as HivesOverview from '@/models/HivesOverview'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import calculateTimeWindow from '@/helpers/calculate_time_window'
import HiveChartSeriesProvider from '@/helpers/HiveChartSeriesProvider'

export const state = () => ({
  loading: true,
  hiveId: null,
  pastToolbar: ['1d', '1w', '4w', '12w', '24w', '52w'],
  metricPast: '1w',
  metricSince: null,
  metricTill: null
})

export const getters = {
  hive (state) {
    return HivesOverview.Hive.query().with('sites').find(state.hiveId)
  },
  getField: state => (name) => {
    return state[name]
  },
  hiveChartSeriesProvider (_state, getters) {
    if (!getters.hive) { return null }
    return new HiveChartSeriesProvider(getters.hive)
  },
  chartSeries (_state, getters) {
    if (!getters.hiveChartSeriesProvider) { return null }
    return getters.hiveChartSeriesProvider.series()
  },
  chartYAxis (_state, getters) {
    if (!getters.hiveChartSeriesProvider) { return null }
    return getters.hiveChartSeriesProvider.yAxis()
  },
  chartYFormatter (state) {
    return ChartFormatters[state.chartType]
  },
  chartTooltipYFormatter (state) {
    if (state.chartType === 'normalizedWeight') {
      return (value, { seriesIndex, dataPointIndex, w }) => {
        let weight = parseFloat(
          w.config.series[seriesIndex].data[dataPointIndex].weight
        )
        let weightLabel = ChartFormatters.weight(weight)
        let normalizedWeightLabel = ChartFormatters.weight(
          value,
          dataPointIndex,
          true
        )
        return `${weightLabel} (${normalizedWeightLabel})`
      }
    }
    return ChartFormatters[state.chartType]
  },

  chartColors (_state, getters) {
    return getters.hiveChartSeriesProvider.colors()
  }
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  setHiveId (state, hiveId) {
    state.hiveId = hiveId
  },

  setRange (state, { past, since, till }) {
    state.metricPast = past
    state.metricSince = since
    state.metricTill = till
  }
}

export const actions = {
  async load ({ commit, state }) {
    commit('setLoading', true)

    let variables = {
      ids: [state.hiveId],
      metricPast: state.metricPast,
      metricSince: state.metricSince,
      metricTill: state.metricTill
    }

    if (variables.metricPast === 'custom') {
      variables.metricPast = null
    } else {
      variables.metricSince = null
      variables.metricTill = null
    }

    variables.metricTime = calculateTimeWindow({
      past: variables.metricPast,
      since: variables.metricSince,
      till: variables.metricTill
    })

    let { data } = await getClient(this).query({
      query: hivesOverviewQuery,
      variables
    })

    HivesOverview.Hive.deleteAll()

    let hivesData = data.currentAccount.hives.nodes.map(HivesOverview.Hive.prepare)

    HivesOverview.Hive.insert({
      data: hivesData
    })
    commit('hives_navigation/toggleSelectedHive', { id: state.hiveId, value: true }, { root: true })
    commit('setLoading', false)
  },

  setRange ({ commit, dispatch }, { past, since, till }) {
    if (past) {
      commit('setRange', { past })
    } else {
      commit('setRange', { past: 'custom', since, till })
    }
    dispatch('load')
  }
}
