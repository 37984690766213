import { getClient } from '@/lib/apollo_helpers'
import hivesOverviewQuery from '@/gql/hives/hivesOverview'
import * as HivesOverview from '@/models/HivesOverview'
import * as ChartFormatters from '@/helpers/chart_y_formatters'
import calculateTimeWindow from '@/helpers/calculate_time_window'

export const state = () => ({
  loading: true,
  chartType: null,
  pastToolbar: ['1d', '1w', '4w', '12w', '24w', '52w'],
  metricPast: '1w',
  metricSince: null,
  metricTill: null
})

export const getters = {
  hiveIds (_state, getters, rootState, rootGetters) {
    return rootGetters['hives_navigation/selectedHives'].map(hive => hive.id)
  },

  getField: state => (name) => {
    return state[name]
  },

  chartSeries: (state) => {
    let hiveDataAttribute = {
      weight: 'weightDataPoints',
      normalizedWeight: 'normalizedWeightDataPoints',
      temperature: 'temperatureDataPoints',
      humidity: 'humidityDataPoints'
    }[state.chartType]

    return HivesOverview.Hive.all().map(hive => ({
      name: hive.name,
      data: hive[hiveDataAttribute].map(dataPoint => ({
        ...dataPoint,
        x: dataPoint.time,
        y: parseFloat(dataPoint.value)
      }))
    }))
  },

  chartYFormatter (state) {
    return ChartFormatters[state.chartType]
  },

  chartTooltipYFormatter (state) {
    if (state.chartType === 'normalizedWeight') {
      return (value, { seriesIndex, dataPointIndex, w }) => {
        let weight = parseFloat(
          w.config.series[seriesIndex].data[dataPointIndex].weight
        )
        let weightLabel = ChartFormatters.weight(weight)
        let normalizedWeightLabel = ChartFormatters.weight(
          value,
          dataPointIndex,
          true
        )
        return `${weightLabel} (${normalizedWeightLabel})`
      }
    }
    return ChartFormatters[state.chartType]
  }
}

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  setRange (state, { past, since, till }) {
    state.metricPast = past
    state.metricSince = since
    state.metricTill = till
  },

  setChartType (state, chartType) {
    state.chartType = chartType
  }
}

export const actions = {
  async load ({ commit, state, getters }) {
    commit('setLoading', true)

    let variables = {
      ids: getters.hiveIds,
      metricPast: state.metricPast,
      metricSince: state.metricSince,
      metricTill: state.metricTill
    }

    if (variables.metricPast === 'custom') {
      variables.metricPast = null
    } else {
      variables.metricSince = null
      variables.metricTill = null
    }

    variables.metricTime = calculateTimeWindow({
      past: variables.metricPast,
      since: variables.metricSince,
      till: variables.metricTill
    })

    let { data } = await getClient(this).query({
      query: hivesOverviewQuery,
      variables
    })

    HivesOverview.Hive.deleteAll()

    let hivesData = data.currentAccount.hives.nodes.map(HivesOverview.Hive.prepare)

    HivesOverview.Hive.insert({
      data: hivesData
    })
    commit('setLoading', false)
  },

  setRange ({ commit, dispatch }, { past, since, till }) {
    if (past) {
      commit('setRange', { past })
    } else {
      commit('setRange', { past: 'custom', since, till })
    }
    dispatch('load')
  }
}
