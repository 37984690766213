import { Model } from '@vuex-orm/core'

export default class HiveSite extends Model {
  static entity = 'hives_navigation__hives_sites'

  static primaryKey = ['site_id', 'hive_id']

  static fields () {
    return {
      hive_id: this.number(null),
      site_id: this.number(null)
    }
  }
}
