import { Model } from '@vuex-orm/core'
import Site from './Site'
import HiveSite from './HiveSite'
export default class Hive extends Model {
  static entity = 'hives_navigation__hives'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      selected: this.boolean(false),
      sites: this.belongsToMany(Site, HiveSite, 'hive_id', 'site_id'),
      hwSensorPlacementsCount: this.number(null)
    }
  }
}
