import { getClient } from '@/lib/apollo_helpers'
import to from 'await-to-js'
import Hive from '@/models/Hive'
import updateHiveMutation from '~/gql/hives/updateHive'
import createHiveMutation from '~/gql/hives/createHive'

export const state = () => ({
  loading: false
})

export const mutations = {
  setLoading (state, loading) { state.loading = loading }
}

export const actions = {

  async create ({ commit }, attributes) {
    commit('setLoading', true)
    const [err, res] = await to(
      getClient(this).mutate({
        mutation: createHiveMutation,
        variables: {
          name: attributes.name
        }
      })
    )

    commit('setLoading', false)

    if (err) {
      return { success: false, errors: [err.message] }
    }

    const {
      data: {
        create: { result: { success, errors } }
      }
    } = res
    if (!success) {
      return { success: false, errors: errors.fullMessages }
    }

    return { success: true, errors: [] }
  },

  async update ({ commit }, attributes) {
    commit('setLoading', true)
    const [err, res] = await to(
      getClient(this).mutate({
        mutation: updateHiveMutation,
        variables: {
          id: attributes.id,
          name: attributes.name
        }
      })
    )

    commit('setLoading', false)

    if (err) {
      return { success: false, errors: [err.message] }
    }

    const {
      data: {
        update: { result: { success, errors } }
      }
    } = res

    if (!success) {
      return { success: false, errors: errors.fullMessages }
    }

    Hive.update({
      where: attributes.id,
      data: {
        name: attributes.name
      }
    })

    return { success: true, errors: [] }
  }
}
