export const availableColors = [
  '#2E93fA',
  '#66DA26',
  '#E91E63',
  '#546E7A',
  '#FFB01A',
  '#6ED0E0',
  '#BA43A8'
]

export const metricColors = {
  weight: availableColors[0],
  humidity: availableColors[1],
  temperature: availableColors[2],
  voltage: availableColors[3]
}
