import { getClient } from '@/lib/apollo_helpers'
import mySitesQuery from '@/gql/sites/mySites'
import Hive from '@/models/Hive'
import Site from '@/models/Site'
import HiveSite from '@/models/HiveSite'
import { without, uniq } from 'lodash'

export const state = () => ({
  loading: true,
  selectedHives: []
})

export const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },

  toggleSelectedHive(state, {id, value}) {
    let idInt = parseInt(id)
    if (!value) {
      state.selectedHives = without(state.selectedHives, idInt)
    } else {
      state.selectedHives = uniq([...state.selectedHives, idInt])
    }
  },

  resetSelectedHives(state) {
    state.selectedHives = []
  }
}

export const getters = {
  selectedHives (state) {
    return Hive.query().where('id', state.selectedHives).get()
  },

  isSelected (state) {
    return (id) => {
      return state.selectedHives.indexOf(id) != -1
    }
  }
}

export const actions = {
  async load (context) {
    context.commit('setLoading', true)
    let { data } = await getClient(this).query({
      query: mySitesQuery
    })


    Site.insertOrUpdate({
      data: data.currentAccount.sites.map(site => ({
        id: site.id,
        name: site.name,
        latitude: site.latitude,
        longitude: site.longitude,
        hives: site.hives.map(hive => ({
          id: hive.id,
          name: hive.name,
          hwSensorPlacementsCount: hive.hwSensorPlacements.length
        }))
      }))
    })
    context.commit('setLoading', false)
  },

  toggleHive ({ dispatch, commit }, { id, value }) {
    commit('toggleSelectedHive', {id, value})
    dispatch('selectionRedirect')
  },

  selectOneHive ({ dispatch, commit }, { id }) {
    commit('resetSelectedHives')
    commit('toggleSelectedHive', {id, value: true})
    dispatch('selectionRedirect')
  },

  selectionRedirect ({ getters }) {
    if (getters.selectedHives.length === 1) {
      let hive = getters.selectedHives[0]
      if (hive.hwSensorPlacementsCount > 0) {
        this.app.router.push(`/hives/${hive.id}/sensors_overview`)
      } else {
        this.app.router.push(`/hives/${hive.id}/overview`)
      }
    } else if (
      getters.selectedHives.length > 1 &&
      this.$router.currentRoute.name !== 'overview-chartType-hives'
    ) {
      this.app.router.push('/overview/weight/hives')
    } else if (
      getters.selectedHives.length === 0 &&
      this.$router.currentRoute.name !== 'hives'
    ) {
      this.app.router.push('/hives')
    }
  }
}
