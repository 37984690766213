import { Model } from '@vuex-orm/core'
import SensorPlacement from './SensorPlacement'

export default class Hive extends Model {
  static entity = 'sensors_overview__hives'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      internalName: this.string(''),
      sensorPlacements: this.hasMany(SensorPlacement, 'hiveId'),
      hwSensorPlacementsCount: this.number(null)
    }
  }
}
