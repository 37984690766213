import { Model } from '@vuex-orm/core'
import { $t } from '@/helpers/i18n_helpers'
import SensorPlacement from './SensorPlacement'

export default class SensorPlacementMetricDataFrame extends Model {
  static entity = 'sensors_overview__sensor_placement_metric_data_frames'

  static primaryKey = ['metric', 'sensorPlacementId']

  static fields () {
    return {
      metric: this.string(''),
      sensorPlacementId: this.number(null),
      sensorPlacement: this.belongsTo(SensorPlacement, 'sensorPlacementId'),
      data: this.attr([]),
      min: this.number(null),
      max: this.number(null)
    }
  }

  get label () {
    return $t(`hive_overview.metrics.${this.metric}`)
  }

  get labelWithSensor () {
    return $t(`hive_overview.metrics.${this.metric}`) + ` (${this.sensorPlacement.name})`
  }
}
