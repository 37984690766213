import { compact } from 'lodash'

export function normalizeData (data) {
  let values = data.map(({ value }) => parseFloat(value))
  let withoutNulls = compact(values)
  let firstValue = withoutNulls[0]
  return data.map(({ value, time }) => ({
    value: parseFloat(value) - firstValue,
    weight: value,
    time
  }))
}
