
import { compact, join } from 'lodash'

export function $t (key) {
  // window.i18n is set on every page load using middleware/i18n
  if (!window.i18n) { throw new Error('window.i18n is undefined, the code has been probably executed outside of nuxt page load') }
  return window.i18n.t(key)
}

export function $tc (key, count) {
  // window._app is set on every page load using middleware/i18n
  if (!window.i18n) { throw new Error('window.i18n is undefined, the code has been probably executed outside of nuxt page load') }
  return window.i18n.tc(key, count)
}

export function durationToHuman (duration, durationUnit) {
  const unitInSeconds = {
    seconds: 1,
    minutes: 60,
    hours: 60 * 60,
    days: 60 * 60 * 24
  }

  const extractUnit = (duration, unit) => (
    (duration - (duration % unitInSeconds[unit])) / unitInSeconds[unit]
  )

  let durationInSeconds = duration * unitInSeconds[durationUnit]

  let days = extractUnit(durationInSeconds, 'days')
  durationInSeconds = durationInSeconds - (days * unitInSeconds.days)

  let hours = extractUnit(durationInSeconds, 'hours')
  durationInSeconds = durationInSeconds - (hours * unitInSeconds.hours)

  let minutes = extractUnit(durationInSeconds, 'minutes')
  durationInSeconds = durationInSeconds - (minutes * unitInSeconds.minutes)

  let seconds = durationInSeconds

  const translations = compact([
    days && $tc('durations.days', days),
    hours && $tc('durations.hours', hours),
    minutes && $tc('durations.minutes', minutes),
    seconds && $tc('durations.seconds', seconds)
  ])
  return join(translations, ' ')
}
