import { Database } from '@vuex-orm/core'
import User from '@/models/User'
import Account from '@/models/Account'
import AccountMembership from '@/models/AccountMembership'
import Session from '@/models/Session'

import Sensor from '@/models/Sensor'
import Hive from '@/models/Hive'
import Site from '@/models/Site'
import HiveSite from '@/models/HiveSite'
import SiteMetricStats from '@/models/SiteMetricStats'
import HiveMetricStats from '@/models/HiveMetricStats'

import * as HivesNavigation from '@/models/HivesNavigation'
import * as HivesOverview from '@/models/HivesOverview'
import * as Devices from '@/models/Devices'
import * as SensorsOverview from '@/models/SensorsOverview'

const database = new Database()

database.register(Session)
database.register(User)
database.register(Account)
database.register(AccountMembership)

database.register(Sensor)
database.register(Hive)
database.register(Site)
database.register(HiveSite)
database.register(SiteMetricStats)
database.register(HiveMetricStats)

database.register(HivesNavigation.Site)
database.register(HivesNavigation.Hive)
database.register(HivesNavigation.HiveSite)

database.register(HivesOverview.Hive)

database.register(Devices.Hub)

database.register(SensorsOverview.Hive)
database.register(SensorsOverview.SensorPlacement)
database.register(SensorsOverview.SensorPlacementMetricDataFrame)

window.database = database

let models = {}
database.entities.forEach((entity) => {
  models[entity.name] = entity.model
})

window.models = models

export default database
