import { Model } from '@vuex-orm/core'
import Hive from './Hive'
import HiveSite from './HiveSite'
import SiteMetricStats from './SiteMetricStats'

export default class Site extends Model {
  static entity = 'sites'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      latitude: this.attr(null),
      longitude: this.attr(null),
      hives: this.belongsToMany(Hive, HiveSite, 'site_id', 'hive_id'),
      metricStats: this.hasOne(SiteMetricStats, 'siteId')
    }
  }
}
