import axios from 'axios'
import to from 'await-to-js'
import qs from 'qs'
import Cache from '@/lib/cache'
import Hive from '@/models/Hive'

// const Cache = () => {}
const WEATHER_URL = 'https://api.openweathermap.org/data/2.5/onecall'
const API_KEY = 'e219fef35830bce11df628800e4e07b4'
const CACHE = new Cache(2 * 60 * 1000) // 2 minutes TTL

export const state = () => ({
  show: false,
  expanded: false,
  site: null,
  forecast: []
})

export const mutations = {
  setSite (state, site) {
    state.site = site
  },

  setForecast (state, forecast) {
    state.forecast = forecast
  },

  show (state) {
    state.show = true
  },

  hide (state) {
    state.show = false
    state.forecast = []
    state.site = null
  },

  toggle (state) {
    state.expanded = !state.expanded
  }
}

export const actions = {
  async load ({ commit }, hiveId) {
    const site = Hive.query().with('sites').find(hiveId)
      .sites[0]

    if (!site.latitude || !site.longitude) { return false }
    commit('setSite', site)
    const query = qs.stringify({
      lat: site.latitude,
      lon: site.longitude,
      appid: API_KEY,
      units: 'metric'
    })
    const url = `${WEATHER_URL}?${query}`
    const cacheValue = CACHE.get(url)
    let err, response
    if (cacheValue) {
      ;[err, response] = [null, cacheValue]
    } else {
      ;[err, response] = await to(axios.get(url))
      CACHE.put(url, response)
    }

    if (err) {
      return false
    } else {
      commit('setForecast', response.data.daily)
      commit('show')
    }
  }
}
