export default function (context) {
  console.log('I18n config init')
  return {
    fallbackLocale: 'cs',
    pluralizationRules: {
      cs (choice, _choicesLength) {
        let choiceIndex
        if (choice === 0) { choiceIndex = 0 }
        if (choice === 1) { choiceIndex = 1 }
        if (choice >= 2 && choice <= 4) { choiceIndex = 2 }
        if (choice >= 5) { choiceIndex = 3 }
        return choiceIndex
      }
    }
  }
}
