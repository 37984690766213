import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27400d8c = () => interopDefault(import('../pages/account_refresh.vue' /* webpackChunkName: "pages/account_refresh" */))
const _27fcfc89 = () => interopDefault(import('../pages/hives/index.vue' /* webpackChunkName: "pages/hives/index" */))
const _16d9a028 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _50adfe22 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _11197a5f = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _17bb42c0 = () => interopDefault(import('../pages/devices/hubs/index.vue' /* webpackChunkName: "pages/devices/hubs/index" */))
const _4c4dc18a = () => interopDefault(import('../pages/hives/debug.vue' /* webpackChunkName: "pages/hives/debug" */))
const _f4cb956a = () => interopDefault(import('../pages/hives/librosa_statistics.vue' /* webpackChunkName: "pages/hives/librosa_statistics" */))
const _d3e8e828 = () => interopDefault(import('../pages/hives/sound_statistics.vue' /* webpackChunkName: "pages/hives/sound_statistics" */))
const _61a28908 = () => interopDefault(import('../pages/hives/statistics.vue' /* webpackChunkName: "pages/hives/statistics" */))
const _ad768fb2 = () => interopDefault(import('../pages/profile/change_password.vue' /* webpackChunkName: "pages/profile/change_password" */))
const _94af45d8 = () => interopDefault(import('../pages/sites/dashboard.vue' /* webpackChunkName: "pages/sites/dashboard" */))
const _edc7b188 = () => interopDefault(import('../pages/users/confirm.vue' /* webpackChunkName: "pages/users/confirm" */))
const _8b86b352 = () => interopDefault(import('../pages/users/register.vue' /* webpackChunkName: "pages/users/register" */))
const _3810edf0 = () => interopDefault(import('../pages/devices/hubs/_id.vue' /* webpackChunkName: "pages/devices/hubs/_id" */))
const _d35f0810 = () => interopDefault(import('../pages/devices/sensors/_id/index.vue' /* webpackChunkName: "pages/devices/sensors/_id/index" */))
const _2dcd14c5 = () => interopDefault(import('../pages/devices/sensors/_id/change_hive.vue' /* webpackChunkName: "pages/devices/sensors/_id/change_hive" */))
const _53cb29fc = () => interopDefault(import('../pages/sounds/_id.vue' /* webpackChunkName: "pages/sounds/_id" */))
const _60e73294 = () => interopDefault(import('../pages/hives/_id/dashboard.vue' /* webpackChunkName: "pages/hives/_id/dashboard" */))
const _01204d15 = () => interopDefault(import('../pages/hives/_id/observations.vue' /* webpackChunkName: "pages/hives/_id/observations" */))
const _74566ec7 = () => interopDefault(import('../pages/hives/_id/overview.vue' /* webpackChunkName: "pages/hives/_id/overview" */))
const _11021b4d = () => interopDefault(import('../pages/hives/_id/sensors_overview.vue' /* webpackChunkName: "pages/hives/_id/sensors_overview" */))
const _e8e75adc = () => interopDefault(import('../pages/hives/_id/sounds.vue' /* webpackChunkName: "pages/hives/_id/sounds" */))
const _6b619bf4 = () => interopDefault(import('../pages/overview/_chartType/hives.vue' /* webpackChunkName: "pages/overview/_chartType/hives" */))
const _2aeb8cd5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account_refresh",
    component: _27400d8c,
    name: "account_refresh"
  }, {
    path: "/hives",
    component: _27fcfc89,
    name: "hives"
  }, {
    path: "/login",
    component: _16d9a028,
    name: "login"
  }, {
    path: "/profile",
    component: _50adfe22,
    name: "profile"
  }, {
    path: "/test",
    component: _11197a5f,
    name: "test"
  }, {
    path: "/devices/hubs",
    component: _17bb42c0,
    name: "devices-hubs"
  }, {
    path: "/hives/debug",
    component: _4c4dc18a,
    name: "hives-debug"
  }, {
    path: "/hives/librosa_statistics",
    component: _f4cb956a,
    name: "hives-librosa_statistics"
  }, {
    path: "/hives/sound_statistics",
    component: _d3e8e828,
    name: "hives-sound_statistics"
  }, {
    path: "/hives/statistics",
    component: _61a28908,
    name: "hives-statistics"
  }, {
    path: "/profile/change_password",
    component: _ad768fb2,
    name: "profile-change_password"
  }, {
    path: "/sites/dashboard",
    component: _94af45d8,
    name: "sites-dashboard"
  }, {
    path: "/users/confirm",
    component: _edc7b188,
    name: "users-confirm"
  }, {
    path: "/users/register",
    component: _8b86b352,
    name: "users-register"
  }, {
    path: "/devices/hubs/:id",
    component: _3810edf0,
    name: "devices-hubs-id"
  }, {
    path: "/devices/sensors/:id",
    component: _d35f0810,
    name: "devices-sensors-id"
  }, {
    path: "/devices/sensors/:id?/change_hive",
    component: _2dcd14c5,
    name: "devices-sensors-id-change_hive"
  }, {
    path: "/sounds/:id?",
    component: _53cb29fc,
    name: "sounds-id"
  }, {
    path: "/hives/:id/dashboard",
    component: _60e73294,
    name: "hives-id-dashboard"
  }, {
    path: "/hives/:id/observations",
    component: _01204d15,
    name: "hives-id-observations"
  }, {
    path: "/hives/:id/overview",
    component: _74566ec7,
    name: "hives-id-overview"
  }, {
    path: "/hives/:id/sensors_overview",
    component: _11021b4d,
    name: "hives-id-sensors_overview"
  }, {
    path: "/hives/:id/sounds",
    component: _e8e75adc,
    name: "hives-id-sounds"
  }, {
    path: "/overview/:chartType?/hives",
    component: _6b619bf4,
    name: "overview-chartType-hives"
  }, {
    path: "/",
    component: _2aeb8cd5,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
