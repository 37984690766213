import moment from 'moment'

function hoursFromTimeString (timeString) {
  let matches = timeString.match(/(\d+)([mhdw])/)
  if (!matches) { return 0 }

  return moment.duration(matches[1], matches[2])
}

export default function calculateTimeWindow ({ past, since, till }) {
  let duration
  if (past) {
    duration = hoursFromTimeString(past)
  } else {
    duration = moment.duration(moment(till).diff(moment(since)))
  }

  if (duration <= moment.duration(18, 'h')) {
    return '15m'
  } else if (duration <= moment.duration(1, 'd')) {
    return '30m'
  } else if (duration <= moment.duration(4, 'd')) {
    return '1h'
  } else if (duration <= moment.duration(1, 'w')) {
    return '2h'
  } else if (duration <= moment.duration(2, 'w')) {
    return '3h'
  } else if (duration <= moment.duration(3, 'w')) {
    return '1d'
  } else if (duration <= moment.duration(4, 'w')) {
    return '1d'
  } else if (duration <= moment.duration(26, 'w')) {
    return '1d'
  } else if (duration <= moment.duration(32, 'w')) {
    return '3d'
  } else if (duration <= moment.duration(52, 'w')) {
    return '1w'
  } else {
    return '1d'
  }
}
