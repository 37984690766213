import { Model } from '@vuex-orm/core'
import { compact, last } from 'lodash'
import { normalizeData } from '@/helpers/data_point_helpers'
import * as ChartFormatters from '@/helpers/chart_y_formatters'

export default class Hive extends Model {
  static entity = 'hives_overview__hives'

  static fields () {
    return {
      id: this.number(null),
      name: this.string(''),
      internalName: this.string(''),
      activeMetrics: this.attr([]),
      weightDataPoints: this.attr([]),
      normalizedWeightDataPoints: this.attr([]),
      temperatureDataPoints: this.attr([]),
      humidityDataPoints: this.attr([]),
      recentWeightDataPoints: this.attr([]),
      recentTemperatureDataPoints: this.attr([]),
      recentHumidityDataPoints: this.attr([]),
      recentVoltageDataPoints: this.attr([])
    }
  }

  static prepare (hive) {
    return {
      id: hive.id,
      name: hive.name,
      internalName: hive.internalName,
      activeMetrics: hive.activeMetrics,
      weightDataPoints: hive.sensorMetricSeries.weight,
      normalizedWeightDataPoints: normalizeData(hive.sensorMetricSeries.weight),
      temperatureDataPoints: hive.sensorMetricSeries.dhtTemp,
      humidityDataPoints: hive.sensorMetricSeries.dhtHum,
      recentWeightDataPoints: hive.recentMetricSeries.weight,
      recentTemperatureDataPoints: hive.recentMetricSeries.dhtTemp,
      recentHumidityDataPoints: hive.recentMetricSeries.dhtHum,
      recentVoltageDataPoints: hive.recentMetricSeries.voltage
    }
  }

  get recentTemperature () {
    let val = last(
      compact(this.recentTemperatureDataPoints.map(({ value }) => value))
    )
    if (!val) { return ' - ' }
    return ChartFormatters.temperature(Number(val))
  }

  get recentHumidity () {
    let val = last(
      compact(this.recentHumidityDataPoints.map(({ value }) => value))
    )
    if (!val) { return ' - ' }
    return ChartFormatters.humidity(Number(val))
  }

  get recentWeight () {
    let val = last(
      compact(this.recentWeightDataPoints.map(({ value }) => value))
    )
    if (!val) { return ' - ' }
    return ChartFormatters.weight(Number(val))
  }

  get recentVoltage () {
    let val = last(
      compact(this.recentVoltageDataPoints.map(({ value }) => value))
    )
    return ChartFormatters.voltage(val)
  }
}
